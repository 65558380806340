import React, { useMemo, useState, useEffect} from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Folder from '@mui/icons-material/Folder';
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { HashRouter, Routes, Route, Link as RouteLink, useLocation } from 'react-router-dom';

import downloadFile from './downloadFile';

const TITLE = '慧泽互联转存站';

function FilesList() {
  const { pathname } = useLocation();

  const paths = useMemo(
    () => pathname.split('/').filter(it => it !== '').map(it => decodeURIComponent(it)),
    [pathname],
  );

  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const run = async () => {
      setFiles([]);
      setLoading(true);

      try {
        const res = await fetch(`https://libc.cc:18080${pathname}`);
        setFiles(await res.json());
      } finally {
        setLoading(false);
      }
    }

    run();
  }, [pathname]);

  const requestDownloadFile = (file) => {
    // eslint-disable-next-line no-restricted-globals
    if (file.type !== 'directory' && confirm('确定下载此文件？')) {
      downloadFile(`https://libc.cc:18080${pathname}/${file.name}`, file.name);
    }
  }

  return (
    <Box sx={{ pt: 7, pb: 4 }}>
      <Box sx={{position: 'fixed', left: 0, top: 0, width: '100%', zIndex: 1, background:'white' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ p: 2 }}>
          <Link component={RouteLink} key="/" underline="none" to="/">
            {TITLE}
          </Link>
          {paths.map((path, i, { length }) => i < length - 1 ? (
            <Link component={RouteLink} key={path} underline="none" to={encodeURIComponent(path)}>
              {path}
            </Link>) : (
              <Typography key={path} color="text.primary">{path}</Typography>
            )
          )}
        </Breadcrumbs>
        <Divider />
      </Box>
      {loading ? (
        <FilesListSkeleton count={1} />
      ) :(
        <List>
          {files.map((file) => (
            <ListItem key={file.name} disablePadding>
              {file.type === 'directory' ? (
                <ListItemButton component={RouteLink} to={file.name}>
                  <ListItemIcon>
                    <Folder fontSize="large" color="primary"  />
                  </ListItemIcon>
                  <ListItemText primary={file.name} secondary={file.mtime} />
                </ListItemButton>
              ): (
                <ListItemButton onClick={() => requestDownloadFile(file)}>
                  <ListItemIcon>
                    <InsertDriveFile fontSize="large" color="action" />
                  </ListItemIcon>
                  <ListItemText primary={file.name} secondary={file.mtime} />
                </ListItemButton>)}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}

function FilesListSkeleton({ count }) {
  return (
    <List>
      {Array(count).fill().map((_, i) => (
        <ListItem key={i}>
          <ListItemIcon>
            <Skeleton variant="circular" width={32} height={32} />
          </ListItemIcon>
          <ListItemText primary={<Skeleton variant="text" />} secondary={<Skeleton width={210} variant="text" />} />
        </ListItem>
      ))}
    </List>
  );
}

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="*" element={<FilesList />} />
      </Routes>
    </HashRouter>
  )
}

